import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import * as API from '../api/index';
import { toast } from 'react-toastify';
import { CheckCircle } from 'react-feather';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { IMG } from '../api/constant';

const initialData = {
  name: '',
  category_id: '',
  subcategory_id: '2',
  description: '',
  minutes: '',
  second: '',
  amount: '',
  mood: '',
  genre: '',
  occasion: '',
};

const validationSchema = Yup.object({
  name: Yup.string().required('Title is required'),
  occasion: Yup.string().required('Occasion is required'),
  genre: Yup.string().required('Genre is required'),
  mood: Yup.string().required('Mood is required'),
  amount: Yup.number().required('Amount is required'),
  description: Yup.string().required('Description is required'),
});

const EditSong = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [imageData, setImageData] = useState('');
  const [catagoriData, setCatagoriData] = useState([]);
  const [fileName, setFileName] = useState('');
  const [thumFilename, setThumFilename] = useState('');
  const [songThumb, setSongThumb] = useState('');

  const formik = useFormik({
    initialValues: initialData,
    validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const token = localStorage.getItem('_tokenCode');
        const reqObj = {
          ...values,
          image: songThumb,
          id: location.state.id,
          filename: fileName || values.filename,
          thumFilename: thumFilename || values.thumFilename,
        };

        const response = await API.update_songs(reqObj, token);
        if (response.data.success) {
          toast.success(response.data.msg);
          navigate('/song-list');
        } else {
          toast.error(response.data.msg);
        }
      } catch (error) {
        toast.error('Failed to update song.');
      } finally {
        setIsLoading(false);
      }
    },
  });

  console.log('formik: ', formik);

  const songsUploading = (e) => {
    let file = e.target.files[0];
    if (file.type === 'audio/mp3' || file.type === 'audio/mpeg') {
      setFileName(file.name);
      var reader = new FileReader();
      reader.onloadend = function () {
        setImageData(reader.result);
        formik.setFieldValue('music_file', reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      toast.error('Please upload only MP3 file.');
    }
  };

  const imageUploadingThum = (e) => {
    let file = e.target.files[0];
    if (file && (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg')) {
      setThumFilename(file.name);
      var reader = new FileReader();
      reader.onloadend = function () {
        setSongThumb(reader.result);
        formik.setFieldValue('image', reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      toast.error('Please upload only image files (JPEG,JPG or PNG).');
    }
  };

  const commonDataTable = async () => {
    const token = localStorage.getItem('_tokenCode');
    try {
      const response = await API.getSongByid(location.state.id, token);
      setCatagoriData(response.data.data.categories);
      formik.setValues(response.data.data);
      if (response.data.is_login === false) {
        localStorage.removeItem('isLogin');
        if (localStorage.getItem('isLogin') === null) {
          navigate('/');
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    commonDataTable();
  }, []);

  return (
    <div className='col-lg-12 layout-spacing'>
      <div className='statbox widget box box-shadow mb-4'>
        <div className='widget-header'>
          <div className='row'>
            <div className='col-xl-12 col-md-12 col-sm-12 col-12'>
              <h4>Edit Music</h4>
            </div>
          </div>
        </div>
        <div className='widget-content widget-content-area'>
          <form onSubmit={formik.handleSubmit}>
            <div className='row'>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label>
                    Title <span className='text-danger'>*</span>
                  </label>
                  <input type='text' className='form-control' placeholder='Enter here' {...formik.getFieldProps('name')} />
                  {formik.touched.name && formik.errors.name ? <div className='text-danger'>{formik.errors.name}</div> : null}
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label>
                    Occasion <span className='text-danger'>*</span>
                  </label>
                  <select
                    className='form-control'
                    {...formik.getFieldProps('occasion')}
                    onChange={(e) => {
                      formik.setFieldValue('occasion', e.target.value);
                    }}
                  >
                    <option value=''>--- Select ---</option>
                    {catagoriData?.Occasion?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {formik.touched.occasion && formik.errors.occasion ? <div className='text-danger'>{formik.errors.occasion}</div> : null}
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label>
                    Genre <span className='text-danger'>*</span>
                  </label>
                  <select
                    className='form-control'
                    {...formik.getFieldProps('genre')}
                    onChange={(e) => {
                      formik.setFieldValue('genre', e.target.value);
                    }}
                  >
                    <option value=''>--- Select ---</option>
                    {catagoriData?.Genre?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {formik.touched.genre && formik.errors.genre ? <div className='text-danger'>{formik.errors.genre}</div> : null}
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label>
                    Mood <span className='text-danger'>*</span>
                  </label>
                  <select
                    className='form-control'
                    {...formik.getFieldProps('mood')}
                    onChange={(e) => {
                      formik.setFieldValue('mood', e.target.value);
                    }}
                  >
                    <option value=''>--- Select ---</option>
                    {catagoriData?.Mood?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {formik.touched.mood && formik.errors.mood ? <div className='text-danger'>{formik.errors.mood}</div> : null}
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label>
                    Amount <span className='text-danger'>*</span>
                  </label>
                  <input type='number' className='form-control' placeholder='Enter here' {...formik.getFieldProps('amount')} />
                  {formik.touched.amount && formik.errors.amount ? <div className='text-danger'>{formik.errors.amount}</div> : null}
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label>
                    Song File <span className='text-danger'>*</span>
                  </label>
                  <div id='dropzone'>
                    <form encType='multipart/form-data' action='/upload' class='dropzone needsclick dz-clickable'>
                      <label htmlFor='fileT' className='dz-message needsclick'>
                        <div className='icon dripicons dripicons-browser-upload'></div>
                        <span className={imageData ? 'dz-button text-success' : 'dz-button'}>
                          {imageData ? (
                            <span className='d-inline-block mr-2'>
                              <CheckCircle color='green' size='30' />
                            </span>
                          ) : (
                            ''
                          )}
                          {imageData ? fileName : formik.values.musicfile?.split('/').pop() || 'Upload MP3 files here'}
                        </span>
                        <input hidden id='fileT' type='file' onChange={songsUploading} className='image-preview-filepond' />
                      </label>
                    </form>
                  </div>
                </div>
                {/* <div class='form-group'>
                  <label>
                    Song File
                    <span class='text-danger'>*</span>
                  </label>
                  <div id='dropzone'>
                    <form encType='multipart/form-data' action='/upload' class='dropzone needsclick dz-clickable'>
                      <label for='fileT' className='dz-message needsclick'>
                        <div class='icon dripicons dripicons-browser-upload'></div>{' '}
                        <form encType='multipart/form-data'>
                          <span class={imageData ? 'dz-button text-success' : 'dz-button'}>
                            {imageData ? (
                              <span className='d-inline-block mr-2'>
                                <CheckCircle color='green' size='30' />
                              </span>
                            ) : (
                              ''
                            )}
                            {imageData ? fileName : formData.filename ? formData.filename : 'Upload MP3 files here'}
                          </span>
                          <input hidden id='fileT' type='file' onChange={imageUploading} class='image-preview-filepond' />
                        </form>
                      </label>
                    </form>
                  </div>
                </div> */}
              </div>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label>
                    Thumbnail Image <span className='text-danger'>*</span>
                  </label>
                  <div id='dropzone'>
                    <form encType='multipart/form-data' action='/upload' class='dropzone needsclick dz-clickable'>
                      <label htmlFor='file' className='dz-message needsclick'>
                        <div className='icon dripicons dripicons-browser-upload'></div>
                        <span className={songThumb ? 'dz-button text-success' : 'dz-button'}>
                          {songThumb ? (
                            <span className='d-inline-block mr-2'>
                              <CheckCircle color='green' size='30' />
                            </span>
                          ) : (
                            ''
                          )}
                          {songThumb ? thumFilename : formik.values.image?.split('/').pop() || 'Upload thumbnail files here'}
                        </span>
                        <input hidden id='file' type='file' onChange={imageUploadingThum} className='image-preview-filepond' />
                      </label>
                    </form>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <img className='thumImg' src={songThumb || `${IMG}${formik.values.image}`} alt='Thumbnail' />
              </div>
              <div className='col-md-12'>
                <div className='form-group'>
                  <label>
                    Description <span className='text-danger'>*</span>
                  </label>
                  <textarea className='form-control' placeholder='Enter here' rows='3' {...formik.getFieldProps('description')}></textarea>
                  {formik.touched.description && formik.errors.description ? <div className='text-danger'>{formik.errors.description}</div> : null}
                </div>
              </div>
            </div>
            <div className='widget-footer'>
              {isLoading ? (
                <button type='submit' className='btn btn-info mr-2' disabled>
                  Loading...
                </button>
              ) : (
                <button type='submit' className='btn btn-success mr-2'>
                  Update
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditSong;
