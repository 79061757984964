import React, { useEffect, useState } from 'react';
import { CheckCircle } from 'react-feather';
import * as API from '../../api/index';
import { header } from '../../schemas/Validation';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
const initialData = {
  templeteType: '',
  templateFile: '',
  sampleData: '',
  mstart: '',
  messlength: '',
  songlength: '',
  samFile: '',
  temFileName: '',
  name: '',
  amount: '',
  intro_overlay_duration: '0',
  outro_overlay_duration: '0',
};

const convertToSeconds = (time) => {
  const [minutes, seconds] = time.split(':');
  return +minutes * 60 + +seconds;
};
const convertToMMSS = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
};
function formatTime(input) {
  input = input.replace(/\D/g, '');

  function pad(number) {
    return number.toString().padStart(2, '0');
  }
  if (input.length === 6) {
    const hours = input.slice(0, 2);
    const minutes = input.slice(2, 4);
    const seconds = input.slice(4);

    const validHours = Math.min(Math.max(parseInt(hours, 10), 0), 23);
    const validMinutes = Math.min(Math.max(parseInt(minutes, 10), 0), 59);
    const validSeconds = Math.min(Math.max(parseInt(seconds, 10), 0), 59);

    return `${pad(validHours)}:${pad(validMinutes)}:${pad(validSeconds)}`;
  } else if (input.length === 4) {
    const minutes = input.slice(0, 2);
    const seconds = input.slice(2);

    const validMinutes = Math.min(Math.max(parseInt(minutes, 10), 0), 59);
    const validSeconds = Math.min(Math.max(parseInt(seconds, 10), 0), 59);

    return `${pad(validMinutes)}:${pad(validSeconds)}`;
  } else if (input.length === 2) {
    const seconds = input;
    const validSeconds = Math.min(Math.max(parseInt(seconds, 10), 0), 59);

    return `${pad(validSeconds)}`;
  } else {
    return input;
  }
}

const EditTemplate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [chooseTemplate, setChooseTemplate] = useState('');
  const [isTemplete, setIsTemplete] = useState(false);
  const [templateType, setTemplateType] = useState([]);
  const [formData, setFormData] = useState(initialData);
  const [templeteData, setTempleteData] = useState('');
  const [templateFile, setTemplateFile] = useState('');
  const [sampleData, setSampleData] = useState('');
  const [temFileName, setTemFileName] = useState('');
  const [samFile, setSamFile] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [errors, setErrors] = useState({
    mstart: '',
    messlength: '',
    songlength: '',
  });
  console.log('errors', errors);

  const uploadtemplete = (e) => {
    try {
      let file = e.target.files[0];

      if (!file || !file.type.includes('audio/mpeg')) {
        toast.error('Please upload only MP3 audio file.');
        return;
      }

      const tIndex = file.name.indexOf('T');
      if (tIndex !== -1 && !isNaN(file.name[tIndex + 1]) && file.name.includes('_')) {
        try {
          const [mstart, messlengthWithExt] = file.name.split('T')[1].split('_');
          const messlength = convertToMMSS(messlengthWithExt.split('.')[0]);
          const mstartMMSS = convertToMMSS(mstart);

          setFormData((prevData) => ({
            ...prevData,
            mstart: mstartMMSS,
            messlength,
          }));
        } catch (error) {
          console.error('Error processing file name:', error);
        }
      }

      setTemFileName(file.name);
      var reader = new FileReader();
      reader.onloadend = function () {
        setTemplateFile(reader.result);
      };
      reader.readAsDataURL(file);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const uploadSample = (e) => {
    let file = e.target.files[0];
    if (file?.type !== 'audio/mpeg') {
      toast.error('Please upload only MP3 audio file.');
      return;
    }

    setSamFile(file.name);
    var reader = new FileReader();
    reader.onloadend = function () {
      setSampleData(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handalerChanges = (e) => {
    const { name, value } = e.target;

    let formattedValue = value;

    if (name === 'mstart' || name === 'messlength' || name === 'songlength') {
      formattedValue = value.length > 2 ? formatTime(value) : value;
      let errorMessage = '';
      if (formattedValue.length < 4) {
        errorMessage = 'Value needs to be in this particular format. MM:SS';
      }
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: errorMessage,
      }));
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: formattedValue,
    }));
  };

  const validateFields = (fields) => {
    let errors = {};
    let hasErrors = false;

    fields.forEach(({ field, message, validation }) => {
      if (!formData[field] || (validation && !validation(formData[field]))) {
        errors[field] = message;
        hasErrors = true;
      }
    });

    return { errors, hasErrors };
  };

  const addTemplete = async () => {
    setIsTemplete(true);

    const commonFields = [
      { field: 'name', message: 'Name is required.' },
      { field: 'songlength', message: 'Duration Of Song is required.' },
      // { field: 'intro_overlay_duration', message: 'Intro Overlay Duration is required.' },
      // { field: 'outro_overlay_duration', message: 'Outro Overlay Duration is required.' },
      { field: 'amount', message: 'Amount is required.' },
    ];

    let timeFields = [
      { field: 'mstart', message: 'Value needs to be in this particular format: MM:SS', validation: (val) => /^\d{2}:\d{2}$/.test(val) },
      { field: 'messlength', message: 'Value needs to be in this particular format: MM:SS', validation: (val) => /^\d{2}:\d{2}$/.test(val) },
      { field: 'songlength', message: 'Value needs to be in this particular format: MM:SS', validation: (val) => /^\d{2}:\d{2}$/.test(val) },
    ];

    const selectedIsIntroMiddleOutro = selectedValue === 'Intro-Middle-Outro';
    const { errors: commonErrors, hasErrors: hasCommonErrors } = validateFields(commonFields);
    let newErrors = { ...commonErrors };

    if (selectedIsIntroMiddleOutro) {
      const { errors: timeFieldErrors, hasErrors: hasTimeErrors } = validateFields(timeFields);
      newErrors = { ...newErrors, ...timeFieldErrors };
      if (hasCommonErrors || hasTimeErrors) {
        setErrors(newErrors);
        setIsTemplete(false);
        toast.error('Please fix the errors before submitting.');
        return;
      }
    } else if (hasCommonErrors) {
      setErrors(newErrors);
      setIsTemplete(false);
      toast.error('Please fix the errors before submitting.');
      return;
    }

    const errorFiles = {};

    if (!sampleData && !samFile) {
      errorFiles.sampleData = 'Voice-Over Template is required.';
    }

    if (!templateFile && !temFileName) {
      errorFiles.templateFile = 'Upload Prerecorded File is required.';
    }

    if (Object.keys(errorFiles).length > 0) {
      setErrors((prevErrors) => ({ ...prevErrors, ...errorFiles }));
      setIsTemplete(false);
      return;
    }

    setErrors(newErrors);

    try {
      const reqObj = {
        name: formData.name,
        temFileName: temFileName,
        samFile: samFile,
        templeteType: selectedValue ? selectedValue : templeteData,
        templateFile: templateFile,
        sampleData: sampleData,
        songlength: convertToSeconds(formData.songlength),
        templeteId: location?.state?.childId,
        target_normalize: formData.target_normalize,
        intro_overlay_duration: formData.intro_overlay_duration,
        outro_overlay_duration: formData.outro_overlay_duration,
        amount: formData.amount,
        ...(selectedIsIntroMiddleOutro && {
          mstart: convertToSeconds(formData.mstart),
          messlength: convertToSeconds(formData.messlength),
        }),
      };

      const response = await API.updateTamplete(reqObj, header);
      if (response.data.success === 1) {
        toast.success(response?.data?.msg);
        setTemplateFile('');
        setSampleData('');
        navigate('/template-list', { state: { id: location?.state?.id } });
        setIsTemplete(false);
      }
    } catch (error) {
      setIsTemplete(false);
      toast.error('An error occurred while updating the template.');
    }
  };

  const getAllTemplate = async () => {
    try {
      const response = await API.getTempleteType(header);
      if (response?.data?.data) {
        setTemplateType(response.data.data);
      }

      const responseById = await API.getTempleteTypeId(location?.state?.childId, header);
      if (responseById?.data?.data) {
        setChooseTemplate(responseById?.data?.data);
        setFormData({
          ...responseById?.data?.data,
          mstart: convertToMMSS(responseById?.data?.data.mstart),
          messlength: convertToMMSS(responseById?.data?.data.messlength),
          songlength: convertToMMSS(responseById?.data?.data.songlength),
        });
        setSelectedValue(responseById?.data?.data?.templeteType);
        setSamFile(responseById?.data?.data?.samFile);
        setTemFileName(responseById?.data?.data?.temFileName);
      }
    } catch (error) {
      console.error('Error fetching template data: ', error);
    }
  };

  const templeteType = async (e) => {
    const header = localStorage.getItem('_tokenCode');
    const typeData = e.target.value;

    setSelectedValue(typeData);
    if (typeData === 'Intro-Middle-Outro') {
      setTempleteData('Intro-Middle-Outro');
    } else if (typeData === 'Intro-Outro' || typeData === 'Happy birthday') {
      setTempleteData('Intro-Outro');
    } else if (typeData === 'Happy birthday') {
      setTempleteData('Happy birthday');
    }
  };

  useEffect(() => {
    getAllTemplate();
  }, []);
  return (
    <>
      <div class='col-lg-12 layout-spacing'>
        <div class='statbox widget box box-shadow mb-4'>
          <div class='widget-header'>
            <div class='row'>
              <div class='col-xl-9 col-md-9 col-sm-9 col-12'>
                <h4>Edit Voice Over Template</h4>
              </div>
              <div class='col-xl-9 col-md-9 col-sm-9 col-12'>
                <Link state={{ id: location?.state?.id }} className='btn btn-success mr-2' to='/template-list'>
                  Back
                </Link>
              </div>
            </div>
          </div>

          <div class='widget-content widget-content-area'>
            <div className='row'>
              <div className='col-md-9 borderUS'>
                <div className='normal'>
                  <div className='justify-content-center row'>
                    <div className='col-md-6'>
                      <div class='form-group'>
                        <label>
                          Change template type to
                          <span class='text-danger'>*</span>
                        </label>
                        <select className='form-control mb-2' value={selectedValue} onChange={templeteType}>
                          <option>--- Select --- </option>
                          {templateType.map((item, index) => (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                        <span>
                          <strong className='text-danger'>Note : </strong>
                          Choose existing template if no change is required in type of template
                        </span>
                      </div>
                    </div>
                  </div>
                  {templeteData === 'Intro-Middle-Outro' || selectedValue === 'Intro-Middle-Outro' ? (
                    <>
                      <div className='row'>
                        <div className='col-md-4'>
                          <div class='form-group'>
                            <label>
                              Title
                              <span class='text-danger'>*</span>
                            </label>
                            <input type='text' class='form-control' placeholder='Enter here' value={formData.name} name='name' onChange={handalerChanges} />
                            {errors.name && <div class='text-danger'>{errors.name}</div>}
                          </div>
                        </div>
                        <div className='col-md-4'>
                          <div class='form-group'>
                            <label>
                              Voice-Over Template
                              <span class='text-danger'>*</span>
                            </label>
                            <div id='dropzone'>
                              <form encType='multipart/form-data' action='/upload' class='dropzone needsclick dz-clickable'>
                                <label for='file' className='dz-message needsclick'>
                                  <div class='icon dripicons dripicons-browser-upload'></div>{' '}
                                  <form encType='multipart/form-data'>
                                    <span class={templateFile ? 'dz-button text-success' : 'dz-button'}>
                                      {templateFile ? (
                                        <span className='d-inline-block mr-2'>
                                          <CheckCircle color='green' size='30' />
                                        </span>
                                      ) : (
                                        ''
                                      )}

                                      {templateFile ? temFileName : formData.temFileName ? formData.temFileName : 'Upload Voice Over files here'}
                                    </span>
                                    <input hidden id='file' type='file' onChange={uploadtemplete} class='image-preview-filepond' />
                                  </form>
                                </label>
                              </form>
                              {errors.templateFile && <div class='text-danger'>{errors.templateFile}</div>}
                              {errors.temFileName && <div class='text-danger'>{errors.temFileName}</div>}
                            </div>
                          </div>
                        </div>
                        <div className='col-md-4'>
                          <div class='form-group'>
                            <label>
                              Upload Prerecorded File
                              <span class='text-danger'>*</span>
                            </label>
                            <div id='dropzone'>
                              <form encType='multipart/form-data' action='/upload' class='dropzone needsclick dz-clickable'>
                                <label for='files' className='dz-message needsclick'>
                                  <div class='icon dripicons dripicons-browser-upload'></div>{' '}
                                  <form encType='multipart/form-data'>
                                    <span class={sampleData ? 'dz-button text-success' : 'dz-button'}>
                                      {sampleData ? (
                                        <span className='d-inline-block mr-2'>
                                          <CheckCircle color='green' size='30' />
                                        </span>
                                      ) : (
                                        ''
                                      )}
                                      {sampleData ? samFile : formData.samFile ? formData.samFile : 'Upload Sample files here'}
                                    </span>
                                    <input hidden id='files' type='file' onChange={uploadSample} class='image-preview-filepond' />
                                  </form>
                                </label>
                              </form>
                              {errors.sampleData && <div class='text-danger'>{errors.sampleData}</div>}
                              {errors.samFile && <div class='text-danger'>{errors.samFile}</div>}
                            </div>
                          </div>
                        </div>

                        <div className='col-md-4'>
                          <div class='form-group'>
                            <label>
                              Middle Start
                              <span class='text-danger'>*</span>
                            </label>
                            <input type='text' class='form-control' placeholder='MM:SS' value={formData.mstart} name='mstart' onChange={handalerChanges} maxLength={5} />
                            {errors.mstart && <div class='text-danger'>{errors.mstart}</div>}
                          </div>
                        </div>

                        <div className='col-md-4'>
                          <div class='form-group'>
                            <label>
                              Message duration
                              <span class='text-danger'>*</span>
                            </label>
                            <input type='text' class='form-control' placeholder='MM:SS' value={formData.messlength} name='messlength' onChange={handalerChanges} maxLength={5} />
                            {errors.messlength && <div class='text-danger'>{errors.messlength}</div>}
                          </div>
                        </div>

                        <div className='col-md-4'>
                          <div class='form-group'>
                            <label>
                              Duration Of Song
                              <span class='text-danger'>*</span>
                            </label>
                            <input type='text' class='form-control' placeholder='MM:SS' value={formData.songlength} name='songlength' onChange={handalerChanges} maxLength={5} />
                            {errors.songlength && <div class='text-danger'>{errors.songlength}</div>}
                          </div>
                        </div>

                        {/* <div className='col-md-4'>
                          <div class='form-group'>
                            <label>
                              Normalize Song
                              <span class='text-danger'>*</span>
                            </label>
                            <input type='text' class='form-control' placeholder='Enter here' value={formData.target_normalize} name='target_normalize' onChange={handalerChanges} />
                          </div>
                        </div> */}
                        <div className='col-md-4'>
                          <div class='form-group'>
                            <label>
                              Intro Overlay Duration
                              <span class='text-danger'>*</span>
                            </label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter here'
                              value={formData.intro_overlay_duration}
                              name='intro_overlay_duration'
                              // onChange={handalerChanges}
                              onChange={(e) => {
                                if (isNaN(e.target.value)) {
                                  return false;
                                } else {
                                  handalerChanges(e);
                                }
                              }}
                            />
                            {errors.intro_overlay_duration && <div class='text-danger'>{errors.intro_overlay_duration}</div>}
                          </div>
                        </div>
                        <div className='col-md-4'>
                          <div class='form-group'>
                            <label>
                              Outro Overlay Duration
                              <span class='text-danger'>*</span>
                            </label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter here'
                              value={formData.outro_overlay_duration}
                              name='outro_overlay_duration'
                              // onChange={handalerChanges}
                              onChange={(e) => {
                                if (isNaN(e.target.value)) {
                                  return false;
                                } else {
                                  handalerChanges(e);
                                }
                              }}
                            />
                            {errors.outro_overlay_duration && <div class='text-danger'>{errors.outro_overlay_duration}</div>}
                          </div>
                        </div>

                        <div className='col-md-4'>
                          <div class='form-group'>
                            <label>
                              Amount
                              <span class='text-danger'>*</span>
                            </label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter here'
                              value={formData.amount}
                              name='amount'
                              // onChange={handalerChanges}
                              onChange={(e) => {
                                if (isNaN(e.target.value)) {
                                  return false;
                                } else {
                                  handalerChanges(e);
                                }
                              }}
                            />
                            {errors.amount && <div class='text-danger'>{errors.amount}</div>}
                          </div>
                        </div>

                        <div className='col-md-12'>
                          {isTemplete ? (
                            <button class='btn btn-info mr-2'>loading ....</button>
                          ) : (
                            <button onClick={addTemplete} class='btn btn-success mr-2'>
                              Update Templete
                            </button>
                          )}
                        </div>
                      </div>
                    </>
                  ) : selectedValue === 'Happy birthday' || selectedValue === 'Intro-Outro' ? (
                    <>
                      <div className='row'>
                        <div className='col-md-4'>
                          <div class='form-group'>
                            <label>
                              Title
                              <span class='text-danger'>*</span>
                            </label>
                            <input type='text' class='form-control' placeholder='Enter here' value={formData.name} name='name' onChange={handalerChanges} />
                            {errors.name && <div class='text-danger'>{errors.name}</div>}
                          </div>
                        </div>
                        <div className='col-md-4'>
                          <div class='form-group'>
                            <label>
                              Voice-Over Template
                              <span class='text-danger'>*</span>
                            </label>
                            <div id='dropzone'>
                              <form encType='multipart/form-data' action='/upload' class='dropzone needsclick dz-clickable'>
                                <label for='file' className='dz-message needsclick'>
                                  <div class='icon dripicons dripicons-browser-upload'></div>{' '}
                                  <form encType='multipart/form-data'>
                                    <span class={templateFile ? 'dz-button text-success' : 'dz-button'}>
                                      {templateFile ? (
                                        <span className='d-inline-block mr-2'>
                                          <CheckCircle color='green' size='30' />
                                        </span>
                                      ) : (
                                        ''
                                      )}

                                      {templateFile ? temFileName : formData.temFileName ? formData.temFileName : 'Upload Voice Over files here'}
                                    </span>
                                    <input hidden id='file' type='file' onChange={uploadtemplete} class='image-preview-filepond' />
                                  </form>
                                </label>
                              </form>
                              {errors.templateFile && <div class='text-danger'>{errors.templateFile}</div>}
                            </div>
                          </div>
                        </div>
                        <div className='col-md-4'>
                          <div class='form-group'>
                            <label>
                              Upload Prerecorded File
                              <span class='text-danger'>*</span>
                            </label>
                            <div id='dropzone'>
                              <form encType='multipart/form-data' action='/upload' class='dropzone needsclick dz-clickable'>
                                <label for='files' className='dz-message needsclick'>
                                  <div class='icon dripicons dripicons-browser-upload'></div>{' '}
                                  <form encType='multipart/form-data'>
                                    <span class={sampleData ? 'dz-button text-success' : 'dz-button'}>
                                      {sampleData ? (
                                        <span className='d-inline-block mr-2'>
                                          <CheckCircle color='green' size='30' />
                                        </span>
                                      ) : (
                                        ''
                                      )}
                                      {sampleData ? samFile : formData.samFile ? formData.samFile : 'Upload Sample files here'}
                                    </span>
                                    <input hidden id='files' type='file' onChange={uploadSample} class='image-preview-filepond' />
                                  </form>
                                </label>
                              </form>
                              {errors.sampleData && <div class='text-danger'>{errors.sampleData}</div>}
                            </div>
                          </div>
                        </div>
                        <div className='col-md-4'>
                          <div class='form-group'>
                            <label>
                              Duration Of Song
                              <span class='text-danger'>*</span>
                            </label>
                            <input type='text' class='form-control' placeholder='MM:SS' value={formData.songlength} name='songlength' onChange={handalerChanges} maxLength={5} />
                            {errors.songlength && <div class='text-danger'>{errors.songlength}</div>}
                          </div>
                        </div>
                        {/* <div className='col-md-4'>
                          <div class='form-group'>
                            <label>
                              Normalize Song
                              <span class='text-danger'>*</span>
                            </label>
                            <input type='text' class='form-control' placeholder='Enter here' value={formData.target_normalize} name='target_normalize' onChange={handalerChanges} />
                          </div>
                        </div> */}
                        <div className='col-md-4'>
                          <div class='form-group'>
                            <label>
                              Intro Overlay Duration
                              <span class='text-danger'>*</span>
                            </label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter here'
                              value={formData.intro_overlay_duration}
                              name='intro_overlay_duration'
                              // onChange={handalerChanges}
                              onChange={(e) => {
                                if (isNaN(e.target.value)) {
                                  return false;
                                } else {
                                  handalerChanges(e);
                                }
                              }}
                            />
                            {errors.intro_overlay_duration && <div class='text-danger'>{errors.intro_overlay_duration}</div>}
                          </div>
                        </div>
                        <div className='col-md-4'>
                          <div class='form-group'>
                            <label>
                              Outro Overlay Duration
                              <span class='text-danger'>*</span>
                            </label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter here'
                              value={formData.outro_overlay_duration}
                              name='outro_overlay_duration'
                              // onChange={handalerChanges}
                              onChange={(e) => {
                                if (isNaN(e.target.value)) {
                                  return false;
                                } else {
                                  handalerChanges(e);
                                }
                              }}
                            />
                            {errors.outro_overlay_duration && <div class='text-danger'>{errors.outro_overlay_duration}</div>}
                          </div>
                        </div>
                        <div className='col-md-4'>
                          <div class='form-group'>
                            <label>
                              Amount
                              <span class='text-danger'>*</span>
                            </label>
                            <input
                              type='text'
                              class='form-control'
                              placeholder='Enter here'
                              value={formData.amount}
                              name='amount'
                              // onChange={handalerChanges}
                              onChange={(e) => {
                                if (isNaN(e.target.value)) {
                                  return false;
                                } else {
                                  handalerChanges(e);
                                }
                              }}
                            />
                            {errors.amount && <div class='text-danger'>{errors.amount}</div>}
                          </div>
                        </div>
                        <div className='col-md-12'>
                          {isTemplete ? (
                            <button class='btn btn-info mr-2'>loading ....</button>
                          ) : (
                            <button onClick={addTemplete} class='btn btn-success mr-2'>
                              Update Templete
                            </button>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    ''
                  )}

                  {/* <div className="row">
                  <div className="col-md-12">
                    <button class="btn btn-success mr-2">Confirm</button>
                  </div>
                </div> */}
                </div>
              </div>
              <div className='col-md-3'>
                <h5 className='mb-3'>Existing Template Type</h5>
                <ul className='typeoftemplete'>
                  {chooseTemplate === '' ? (
                    <>
                      {selectedValue === 'Intro-Middle-Outro' ? (
                        <li>
                          <CheckCircle color='green' size='20' />
                          <span>Intro-Middle-Outro</span>
                        </li>
                      ) : selectedValue === 'Intro-Outro' ? (
                        <li>
                          <CheckCircle color='green' size='20' />
                          <span>Intro-Outro</span>
                        </li>
                      ) : selectedValue === 'Happy birthday' ? (
                        <li>
                          <CheckCircle color='green' size='20' />
                          <span>Happy birthday</span>
                        </li>
                      ) : (
                        ''
                      )}
                    </>
                  ) : (
                    <>
                      <li>
                        <CheckCircle color='green' size='20' />
                        <span>{chooseTemplate.templeteType}</span>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditTemplate;
